// 护理员档案列表

<template>
  <div class="nurseDetail clearfix">
    <div class="nurseDetail_page">
      <div class="nurseDetail_title base_333_color">
        <img src="../image/shouye(1).png" alt="" />
        <span class="cursor" @click="go()">首页 ></span>
        <span class="cursor"> 护理员档案</span>
      </div>
      <div class="bj" v-if="list == null"></div>
      <div v-else class="nurseDetail_con">
        <a-form layout="inline" :form="form" class="nurseDetail_con_item">
          <a-form-item>
            <input
              placeholder="请填写姓名或年龄"
              v-model="formData.name"
              class="userName"
            />
          </a-form-item>
          <a-form-item>
            <a-select placeholder="请选择性别" @change="handleChange">
              <a-select-option value="1">男</a-select-option>
              <a-select-option value="2">女</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item>
            <a-select placeholder="请选择资质" @change="handleChange1">
              <a-select-option value="11">健康证</a-select-option>
              <a-select-option value="12">养老护理员（等级）</a-select-option>
              <a-select-option value="13">养老护理员（健康照护）</a-select-option>
              <a-select-option value="14" >养老护理员（医疗照护）</a-select-option>
              <a-select-option value="15">护士</a-select-option>
              <a-select-option value="16">护师</a-select-option>
              <a-select-option value="17">主管护师</a-select-option>
              <a-select-option value="18">康复士证</a-select-option>
              <a-select-option value="19">康复师证</a-select-option>
              <a-select-option value="20">健康管理师</a-select-option>
              <a-select-option value="21">营养师</a-select-option>
              <a-select-option value="22">心理咨询师</a-select-option>
              <a-select-option value="23">培训师</a-select-option>
              <a-select-option value="24">中医按摩师</a-select-option>
              <a-select-option value="25">家政员</a-select-option>
              <a-select-option value="26">医师</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item>
           <a-select placeholder="请选择门店"  @change="handleChange2" >
                  <a-select-option
                    v-for="(Load,index) in Load"
                      :key="index"
                      :value="Load.id"
                  >
                  {{Load.name}}
                  </a-select-option>
            </a-select>
          </a-form-item>
          <button type="primary" class="submit" @click="submit">
            <img src="../image/fangdajing.png" />
            <span>查询</span>
          </button>
        </a-form>
        <br />
        <div class="nureslist cursor">
          <div
            v-for="(list, index) in list"
            :key="index"
            class="content"
            @click="onclick(list.id)"
          >
            <a-avatar shape="square"   :src="list.avatar?list.avatar:'../image/nurse_photo.png'"/>
            <!-- <img  /> -->
          
            <div class="content1">
              <div class="title">{{ list.name ? list.name : null }}</div>
              <div class="title">
                {{ list.sex_dictText ? list.sex_dictText : null }}
              </div>
              <div class="title1">
                {{
                  list.workYears != "null"
                    ? "工作经验" + list.workYears + "年"
                    : null
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="paging">
        <a-pagination show-quick-jumper :total="total" @change="onChange" ></a-pagination>
        <a-button class="button">GO </a-button>
      </div>
    </div>

    <!---------------- 右侧导航-------------------->
    <ebutton class="right_btn">
      <template #one>
        <button @click="goContract" class="btn1 cursor base_white_color">
          创建服务合同
        </button>
      </template>
      <template #two>
        <button @click="createUser" class="btn2 cursor base_white_color">
          创建客户信息
        </button>
      </template>
      <!-- <template v-slot:three> 这是插入到three插槽的内容 </template> -->
    </ebutton>
  </div>
</template>

<script>
import ebutton from "../components/RightList"; // 右侧导航 插槽
import { getNurseDetail,getLoad } from "../api/api"; //接口
export default {
  data() {
    return {
      list: null,
      total: null,
       Load:{},
      formData: {
        pageSize: 8,
      },
    };
  },
  created() {
    //页面数据初次请求
    this.submit();
     getLoad().then((res)=>{
      console.log(res.data.result)
      this.Load = res.data.result
    })
  },
  components: { ebutton },
  methods: {
    submit() {
      //按条件查询提交
      let that = this;
      // console.log(that.formData);
      if (that.formData.sex == "null") {
        delete that.formData.sex;
      }
      if (that.formData.certificateType == "null") {
        delete that.formData.certificateType;
      }
      if (that.formData.jobYears == "null") {
        delete that.formData.jobYears;
      }
       if (that.formData.storeId == "null") {
        delete that.formData.storeId;
      }
      delete that.formData.pageNo;
      getNurseDetail(that.formData).then((res) => {
        if (res.status == 200) {
          that.total = res.data.result.total;
          that.list = res.data.result.records;
          // console.log(that.list);
        }
      });
    },
    handleChange(e) {
      this.formData.sex = e;
    },
    handleChange1(e) {
      this.formData.certificateType = e;
    },
    handleChange2(e) {
      // this.formData.jobYears = e;
       this.formData.storeId = e;
    },
     createUser() {
      this.$router.push("/infoOne");
    },
    goContract() {
      this.$router.push("/serveManage");
    },
    onChange(pageNumber) {
      //分页
      let that = this;
      this.formData.pageNo = pageNumber;
      getNurseDetail(that.formData)
        .then((res) => {
          if (res.status == 200) {
            that.list = res.data.result.records;
            // console.log(that.list);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onclick(list) {
      this.$router.push({ path: "/nurseDetail", query: { id: list } });
    },
    go() {
      this.$router.push("./home");
    },
  },
};
</script>

<style scoped>
.nurseDetail {
  background: #fafafd;

  position: relative;
}
.nurseDetail_page {
  width: 1782px;
  text-align: left;
  margin-left: 50px;
}
.nurseDetail_title {
  padding: 28px 0;
  font-size: 22px;
}
.nurseDetail_title img {
  width: 22px;
  height: 22px;
  margin-right: 14px;
  vertical-align: text-top;
}
.bj {
  height: 700px;
  width: 100%;
}
.nurseDetail_con {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 18px 0px rgba(205, 210, 243, 0.27);
  border-radius: 12px;
  /* justify-content: space-between; */
  padding: 26px 156px 48px 58px;
}
.nurseDetail_con_item {
  display: flex;
}

.submit {
  cursor:pointer;
  width: 167px;
  height: 48px;
  background: #fea56c linear-gradient(90deg, #fdb179 0%, #ff9158 100%);
  border-radius: 6px;
}
.submit img {
  width: 19px;
  height: 19px;
  margin-bottom: 5px;
}
.submit span {
  width: 40px;
  height: 20px;
  font-size: 20px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #ffffff;
  line-height: 30px;
}
.nureslist {
  margin-left: -17px;
  display: flex;
  width: 1643px;
  flex-wrap: wrap;
}
.paging {
  width: 45%;
  margin-top: 45px; 
  margin-bottom: 50px;
  margin-right: 120px;
  float: right;
  display: flex;
}
.paging .button{
  margin-left: 10px;
   border-radius: 6px;
   background: #ffff;
}

.userName {
  width: 320px;
  height: 50px;
  border-radius: 8px;
  line-height: 50px;
  padding-left: 20px;
  border: 1px solid #ccc;
  margin-left: 0px;
  font-size: 20px;
  color: #a19b9b;

}
input:focus {
  border: none;
  box-shadow: 0px 0px 3px var(--themeColor);
  border: 1px solid var(--themeColor);
}
input:hover {
  border: 1px solid var(--themeColor);
}
.input {
  width: 302px;
  height: 40px;
  background: #ffffff;
  border-radius: 6px;
  opacity: 0.7;
  border: 1px solid #252323;
}

input::-webkit-input-placeholder {
         /* placeholder颜色  */
         color: #ccc;
   }

.content {
  width: 367px;
  height: 227px;
  background: #ffffff;
  box-shadow: 0px 0px 18px 0px rgba(205, 210, 243, 0.27);
  border-radius: 6px;
  margin-left: 17px;
  margin-bottom: 18px;
  display: flex;
}
.content .img {
  width: 178px;
  height: 178px;
  justify-content: center;
  align-items: center;
  margin-top: 22px;
  margin-left: 19px;
  border-radius: 6px;
}
.content .img img{
  max-width: 100%;
  max-height: 100%;
  border-radius: 6px;
}
.content .content1 {
  width: 110px;
  height: 100px;
  float: right;
  margin-top: 32px;
  margin-left: 38px;
}
.content .content1 img {
  width: 178px;
  height: 178px;
}
.content .content1 .title {
  font-size: 20px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #333333;
  line-height: 33px;
  margin-top: 13px;
}
.content .content1 .title1 {
  font-size: 18px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #a6a6a6;
  line-height: 30px;
  margin-top: 13px;
}

/* ===================================右侧导航样式======================================== */

.right_btn {
  padding: 18px 0;
  font-size: 20px;
  /* float: right; */
  height: 100%;
  position: absolute;
  right: 0;
  top: 0px;
  z-index: 1;
}
.right_btn button {
  width: 172px;
  height: 64px;
  border-radius: 10px;
  margin-top: 32px;
  box-shadow: 0px 0px 16px 0px rgba(205, 210, 243, 0.4);
}
.btn1 {
  background: linear-gradient(90deg, #aee49e 0%, #82d3ea 100%);
}
.btn2 {
  background: linear-gradient(90deg, #fdb179 0%, #ff9158 100%);
}

/* ------------------------------------------------------------ */
:deep(.ant-avatar > img){
  width: 178px;
  height: 178px;
  border-radius: 6px ;
}
:deep(.ant-avatar-square){
   width: 178px;
  height: 178px;
  border-radius: 6px ;
  justify-content: center ;
  align-items: center;
  margin-top: 22px;
  margin-left: 19px;
}
:deep(.ant-col) {
  width: 322px;
}

:deep(.ant-select-selection-placeholder) {
  padding-left: 10px !important;
  font-size: 20px;
  line-height: 46px !important;
}
:deep(.ant-select-selection-item) {
  height: 50px !important;
  line-height: 46px !important;
  padding-left: 10px !important;
  font-size: 20px;
}
:deep(.ant-select-selector) {
  height: 50px !important;
  border-radius: 8px !important;
  line-height: 50px !important;
  border: none !important;
  border: 1px solid #ccc !important;
}
:deep(.ant-select-selector:hover) {
  border: 1px solid var(--themeColor) !important;
}
:deep(.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector) {
  border: 1px solid var(--themeColor) !important;
  outline: 0;
  box-shadow: 0px 0px 4px var(--themeColor);
}
</style>
